import React, { useEffect, useRef } from 'react';
import { Adsense } from '@ctrl/react-adsense';

function GoogleAdsenseBoxDisplay() {
    const adContainerRef = useRef(null);

    useEffect(() => {
        // Load AdSense script if not already present
        if (!document.querySelector(`script[src*="adsbygoogle"]`)) {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}`;
            script.crossOrigin = 'anonymous';
            
            document.body.appendChild(script);
            
            return () => {
                if (document.body.contains(script)) {
                    document.body.removeChild(script);
                }
            };
        }
    }, []);

    useEffect(() => {
        const initAd = () => {
            if (!adContainerRef.current) return;

            // Ensure the container has proper width before initializing
            const containerWidth = adContainerRef.current.offsetWidth;
            if (containerWidth < 250) {
                console.warn('Ad container width is less than required 250px:', containerWidth);
                return;
            }

            const adElement = adContainerRef.current.querySelector('ins.adsbygoogle');
            if (adElement && !adElement.hasAttribute('data-adsbygoogle-initialized')) {
                adElement.setAttribute('data-adsbygoogle-initialized', 'true');
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('Error initializing ad:', e);
                }
            }
        };

        // Initial delay to ensure DOM is ready
        const timeoutId = setTimeout(initAd, 250);

        // Fallback for window load
        window.addEventListener('load', initAd);

        // Cleanup
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('load', initAd);
        };
    }, []);

    if (process.env.REACT_APP_ADS_GOOGLE_ADSENSE !== 'active') {
        return null;
    }

    return (
        <div 
            ref={adContainerRef}
            className="card p-1 m-2"
            style={{
                minWidth: '251px',  // Ensuring minimum width
                maxWidth: '300px',
                width: '100%',
                display: 'block'
            }}
        >
            <Adsense
                client={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}
                slot={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_SLOT}
                style={{ 
                    display: 'block',
                    width: '100%',
                    minWidth: '250px'
                }}
                format="fluid"
                layoutKey='-81+eo+1+2-2'
                adTest='on'
            />
        </div>
    );
}

export default GoogleAdsenseBoxDisplay;