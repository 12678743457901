import React, { useState, useEffect } from 'react';
import {getCookie} from "../../utils/cookies_functions";
import { List, Card, Button, Spin, notification, Space, Avatar } from 'antd';
import { MessageOutlined,  UserOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import '../../css_pages/ForumList.css';
import NavSimple from "../../components/NavSimple";
import GoogleAdsenseBoxDisplay from '../../components/ads/GoogleAdsenseBoxDisplay';

const ForumList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastId, setLastId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        loadInitialPosts();
    }, []);

    const handleNewDebateClick = (e) => {
        e.preventDefault();

        if (!getCookie('api_token')) {
            const redirectUrl = `${process.env.REACT_APP_REMOOX_CORE_ROOT}/api/client/login?route_after=${process.env.REACT_APP_URL}/forum&type=forum`;
            window.location.href = redirectUrl;
        } else {
            navigate('/forum/create', { replace: true });
        }
    };

    const loadInitialPosts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/batch`,
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': getCookie('api_token'),
                        'Authorization': `Bearer ${getCookie('api_token')}`
                    }
                }
            );
            
            if (response.data.message === 'success') {
                setPosts(response.data.posts);
                setHasMore(response.data.has_more);
                setLastId(response.data.last_id);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'No se pudieron cargar los debates'
            });
        } finally {
            setLoading(false);
        }
    };

    const loadMorePosts = async () => {
        if (!hasMore || loading) return;

        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/batch`,
                {
                    params: { start_id: lastId },
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': getCookie('api_token'),
                        'Authorization': `Bearer ${getCookie('api_token')}`
                    }
                }
            );

            if (response.data.message === 'success') {
                setPosts(prevPosts => [...prevPosts, ...response.data.posts]);
                setHasMore(response.data.has_more);
                setLastId(response.data.last_id);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Error al cargar más debates'
            });
        } finally {
            setLoading(false);
        }
    };

    const LoadingIndicator = () => (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
        </div>
    );

    const NoMorePosts = () => (
        <div style={{ textAlign: 'center', padding: '20px', color: '#999' }}>
            No hay más publicaciones para mostrar
        </div>
    );

    const PostCard = ({ post }) => (
        <Card
            hoverable
            style={{ marginBottom: 16 }}
            onClick={(e) => {
                e.preventDefault();
                navigate(`/forum/post/${post.id}`, { replace: true });
            }}
        >
            <Card.Meta
                avatar={
                    <Avatar icon={<UserOutlined />} src={post.user?.profile_photo} />
                }
                title={post.title}
                description={
                    <Space direction="vertical" size={2} style={{ width: '100%' }}>
                        <div className="" style={{ fontSize: '0.9em', whiteSpace: 'normal' }}>
                            Por {post.user.firstname} • {new Date(post.created_at).toLocaleDateString()}
                        </div>
                        <Space>
                            <MessageOutlined style={{ color: '#1890ff' }} />
                            <span>{post.answers_count} respuestas</span>
                        </Space>
                    </Space>
                }
            />
        </Card>
    );

    return (
        <div>
            <NavSimple />
            <div className="py-4 mt-5" style={{margin: 'auto', maxWidth: 900}}>
                <div className="d-flex flex-row align-items-center mb-4 pl-5 pr-5">
                    <h2 className="m-0" style={{fontSize: 15, color: '#5d5d5d'}}>Foro de Ayuda:</h2>
                    <Button
                        type="primary"
                        icon={<EditOutlined style={{fontSize: 18}} />}
                        onClick={handleNewDebateClick}
                        className='ml-3 btn-secondary'
                        style={{fontSize: 13}}
                    >
                        Nuevo Debate
                    </Button>

                </div>

                <InfiniteScroll
                    dataLength={posts.length}
                    next={loadMorePosts}
                    hasMore={hasMore}
                    loader={<LoadingIndicator />}
                    endMessage={<NoMorePosts />}
                    scrollThreshold={0.8}
                >
                    <List
                        dataSource={posts}
                        renderItem={(post) => (
                            <List.Item>
                                <PostCard post={post} />
                            </List.Item>
                        )}
                        style={{ 
                            background: '#fff', 
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                        }}
                    />
                </InfiniteScroll>
            </div>
            <div style={{minWidth: 250}}>
                <GoogleAdsenseBoxDisplay />
            </div>
        </div>
    );
};

export default ForumList;