/**
 * Obtiene el valor del parámetro api_t de la URL y lo almacena en una constante
 * @returns {string|null} El valor del token o null si no existe
 */
const getApiTokenFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const apiToken = urlParams.get('api_t');
    
    if (apiToken) {
        return apiToken;
    }
    
    return null;
};

const loading = (state) => {
    state = state === null;
    const logo_selector = document.getElementById('logo');
    const loading_selector = document.getElementById('loading');

    if(state){
        logo_selector.classList.add('d-none');
        loading_selector.classList.remove('d-none');
    }else{
        logo_selector.classList.remove('d-none');
        loading_selector.classList.add('d-none');
    }
}

export {loading, getApiTokenFromUrl}