import React, { useState, useEffect } from 'react';
import { getCookie } from "../../utils/cookies_functions";
import axios from 'axios';
import EditorToolbar from '../../components/utils/EditorToolbar/index';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

const ForumPostAnswer = ({ postId, isAuthenticated }) => {
    const [answers, setAnswers] = useState([]);
    const [newAnswer, setNewAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editorKey, setEditorKey] = useState(0);
    const [deletingAnswer, setDeletingAnswer] = useState(null);
    const [user, setUser] = useState({});
    const [votingId, setVotingId] = useState(null);

    // Función para formatear la fecha
    const formatDate = (dateString) => {
        const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    // Cargar respuestas al montar el componente
    useEffect(() => {
        const userData = getCookie('usr_data');
        if (userData) {
            try {
                const parsedUser = JSON.parse(userData);
                setUser(parsedUser);
            } catch (e) {
                console.error('Error parsing user data:', e);
                setUser(null);
            }
        }
        fetchAnswers();
    }, [postId]);

    // Obtener las respuestas del post
    const fetchAnswers = async () => {
        try {
            const api_token = getCookie('api_token');
            const response = await axios.get(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/${postId}/answers`,
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Authorization': `Bearer ${api_token}`
                    }
                }
            );
            console.log('response.data', response.data)
            setAnswers(response.data.answers || []);
        } catch (err) {
            setError('No se pudieron cargar las respuestas');
            console.error('Error fetching answers:', err);
        }
    };

    // Enviar una nueva respuesta
    const handleSubmitAnswer = async (e) => {
        e.preventDefault();
        if (!newAnswer.trim() || !isAuthenticated) return;
    
        setLoading(true);
        try {
            const api_token = getCookie('api_token');
            await axios.post(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/answer`,
                {
                    post_id: postId,
                    description: newAnswer
                },
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Authorization': `Bearer ${api_token}`
                    }
                }
            );
            
            setNewAnswer('');
            setEditorKey(prev => prev + 1); // Forzar el reinicio del editor
            await fetchAnswers();
        } catch (err) {
            setError('No se pudo enviar la respuesta');
            console.error('Error submitting answer:', err);
        } finally {
            setLoading(false);
        }
    };

    // Función para eliminar una respuesta
    const handleDeleteAnswer = async (answerId) => {
        if (!window.confirm('¿Estás seguro de que deseas eliminar esta respuesta?')) {
            return;
        }

        setDeletingAnswer(answerId);
        try {
            const api_token = getCookie('api_token');
            await axios.delete(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/answers/${answerId}`,
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Authorization': `Bearer ${api_token}`
                    }
                }
            );
            
            await fetchAnswers(); // Recargar las respuestas
        } catch (err) {
            setError('No se pudo eliminar la respuesta');
            console.error('Error deleting answer:', err);
        } finally {
            setDeletingAnswer(null);
        }
    };

    // Función para manejar los votos
    const handleVote = async (answerId, voteType) => {
        if (!isAuthenticated) {
            setError('Debes iniciar sesión para votar');
            return;
        }

        setVotingId(answerId);
        setError(null);

        try {
            const api_token = getCookie('api_token');
            const response = await axios.post(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/answers/${answerId}/votes`,
                { vote_type: voteType },
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Authorization': `Bearer ${api_token}`
                    }
                }
            );

            setAnswers(answers.map(answer => {
                if (answer.id === answerId) {
                    return {
                        ...answer,
                        votes_count: response.data.votes_count,
                        user_vote: response.data.user_vote
                    };
                }
                return answer;
            }));
        } catch (err) {
            if (err.response?.status === 403) {
                setError('No puedes votar tu propia respuesta');
            } else {
                setError('No se pudo procesar el voto');
            }
            console.error('Error voting:', err);
        } finally {
            setVotingId(null);
        }
    };

    return (
        <div className="forum-answers-section mt-5">
            <h3 className="answers-title mb-4" style={{fontSize: 12, textTransform: 'uppercase'}}>
                Respuestas de Ayuda:
            </h3>
            
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            {/* Formulario para nueva respuesta */}
            {isAuthenticated && (
                <form onSubmit={handleSubmitAnswer} className="mb-4">
                    <div className="form-group">
                    <EditorToolbar
                        key={editorKey}
                        placeholder="Describe detalladamente tu respuesta..."
                        rows={5}
                        enabledButtons={['basic', 'alignment', 'lists', 'insert']}
                        onChange={(e) => {
                            console.log('Editor value:', e.target.value);
                            setNewAnswer(e.target.value || '');
                        }}
                        value={newAnswer}
                        disabled={loading}
                    />
                    </div>
                    <button 
                        type="submit" 
                        className="btn btn-primary mt-2"
                        disabled={loading || !newAnswer.trim()}
                    >
                        {loading ? 'Enviando...' : 'Enviar Respuesta'}
                    </button>
                </form>
            )}

            {/* Lista de respuestas */}
            <div className="answers-list">
                {answers.length === 0 ? (
                    <p className="text-muted">No hay respuestas aún</p>
                ) : (
                    answers.map((answer) => (
                        <div key={answer.id} className="answer-card mb-3 p-3 border rounded bg-light">
                            <div className="answer-content" 
                                 dangerouslySetInnerHTML={{ __html: answer.description }}
                            />
                            <div className="answer-footer mt-3 d-flex flex-row">
                                {isAuthenticated && answer.user_id !== user?.id && (
                                    <div className="btn-group me-2">
                                        <button 
                                            className={`btn btn-sm ${answer.user_vote === 1 ? 'btn-primary' : 'btn-outline-primary'}`}
                                            onClick={() => handleVote(answer.id, 1)}
                                            disabled={votingId === answer.id}
                                            style={{ padding: '0 15px 4px 15px' }}
                                        >
                                            {answer.user_vote === 1 ? 
                                                <ThumbUpIcon sx={{ fontSize: 16 }} /> : 
                                                <ThumbUpOutlinedIcon sx={{ fontSize: 16 }} />
                                            }
                                        </button>
                                        <button 
                                            className={`btn btn-sm ${answer.user_vote === -1 ? 'btn-danger' : 'btn-outline-danger'}`}
                                            onClick={() => handleVote(answer.id, -1)}
                                            disabled={votingId === answer.id}
                                            style={{ padding: '0 15px 4px 15px' }}
                                        >
                                            {answer.user_vote === -1 ? 
                                                <ThumbDownIcon sx={{ fontSize: 16 }} /> : 
                                                <ThumbDownOutlinedIcon sx={{ fontSize: 16 }} />
                                            }
                                        </button>
                                    </div>
                                )}
                                <small className="text-muted mt-1">
                                    {answer?.votes_count || 0} {Math.abs(answer?.votes_count || 0) === 1 ? 'voto' : 'votos'}
                                </small>
                            </div>
                            <div className="answer-header d-flex flex-wrap align-items-center mb-2 mt-3">
                                <div className="user-info w-100" style={{fontSize: 10}}>
                                    {answer.user.firstname} {answer.user.lastname}
                                </div>
                                <small className="text-muted" style={{fontSize: 10}}>
                                    {formatDate(answer.created_at)}
                                </small>
                            </div>
                            {user && answer.user_id === user.id && (
                                <button 
                                    className="btn btn-sm btn-warning"
                                    onClick={() => handleDeleteAnswer(answer.id)}
                                    disabled={deletingAnswer === answer.id}
                                    style={{fontSize: 12}}
                                >
                                    {deletingAnswer === answer.id ? 'Eliminando...' : 'Eliminar respuesta'}
                                </button>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ForumPostAnswer;