import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Tabs } from 'antd';
import KeyboardIcon from './img/keyboard.png';
import BoldIcon from './img/bold.png';
import ItalicIcon from './img/italic.png';
import UnderlineIcon from './img/underline.png';
import AlignLeftIcon from './img/align-left.png';
import AlignCenterIcon from './img/align-center.png';
import AlignRightIcon from './img/align-right.png';
import OrderedListIcon from './img/ordered-list.png';
import UnorderedListIcon from './img/unordered-list.png';
import LinkIcon from './img/link.png';
import './styles.css';

const { TextArea } = Input;
const { TabPane } = Tabs;

const TEXT_SIZES = [
    { value: '12px', label: '12' },
    { value: '14px', label: '14' },
    { value: '16px', label: '16' },
    { value: '18px', label: '18' },
    { value: '20px', label: '20' },
    { value: '24px', label: '24' },
    { value: '28px', label: '28' },
    { value: '32px', label: '32' },
    { value: '36px', label: '36' },
];

const TOOLBAR_BUTTONS = {
    basic: [
        { 
            img: BoldIcon,
            action: 'bold', 
            tooltip: 'Negrita',
            alt: 'Negrita',
            start: '<strong>',
            end: '</strong>'
        },
        { 
            img: ItalicIcon,
            action: 'italic', 
            tooltip: 'Cursiva',
            alt: 'Cursiva',
            start: '<em>',
            end: '</em>'
        },
        { 
            img: UnderlineIcon,
            action: 'underline', 
            tooltip: 'Subrayado',
            alt: 'Subrayado',
            start: '<u>',
            end: '</u>'
        }
    ],
    alignment: [
        { 
            img: AlignLeftIcon,
            action: 'alignLeft', 
            tooltip: 'Alinear izquierda',
            alt: 'Alinear izquierda',
            start: '<div style="text-align: left;">',
            end: '</div>'
        },
        { 
            img: AlignCenterIcon,
            action: 'alignCenter', 
            tooltip: 'Centrar',
            alt: 'Centrar',
            start: '<div style="text-align: center;">',
            end: '</div>'
        },
        { 
            img: AlignRightIcon,
            action: 'alignRight', 
            tooltip: 'Alinear derecha',
            alt: 'Alinear derecha',
            start: '<div style="text-align: right;">',
            end: '</div>'
        }
    ],
    lists: [
        { 
            img: OrderedListIcon,
            action: 'orderedList', 
            tooltip: 'Lista numerada',
            alt: 'Lista numerada',
            start: '<ol>',
            end: '</ol>',
            itemStart: '<li>',
            itemEnd: '</li>'
        },
        { 
            img: UnorderedListIcon,
            action: 'unorderedList', 
            tooltip: 'Lista con viñetas',
            alt: 'Lista con viñetas',
            start: '<ul>',
            end: '</ul>',
            itemStart: '<li>',
            itemEnd: '</li>'
        }
    ],
    fontSize: [
        {
            action: 'fontSize',
            tooltip: 'Tamaño de texto',
            alt: 'Tamaño de texto',
            sizes: TEXT_SIZES,
            start: '<span style="font-size: ',
            end: ';</span>'
        }
    ],
    insert: [
        { 
            img: LinkIcon,
            action: 'link', 
            tooltip: 'Insertar enlace',
            alt: 'Insertar enlace',
            start: '<a href="url">',
            end: '</a>'
        }
    ]
};

const EditorToolbar = ({ 
    value = '', 
    onChange,
    placeholder = '',
    rows = 10,
    showCharCount = true,
    showHtmlHint = true,
    enabledButtons = ['basic', 'alignment', 'lists', 'fontSize', 'insert'],
    customButtons = []
}) => {
    const [charCount, setCharCount] = useState(0);
    const [activeTab, setActiveTab] = useState('0');
    const simpleEditorRef = useRef(null);
    const textAreaRef = useRef(null);
    
    // Mantener sincronizado el contenido del editor simple
    useEffect(() => {
        if (simpleEditorRef.current) {
            const contentToSet = value || '';
            if (simpleEditorRef.current.innerHTML !== contentToSet) {
                simpleEditorRef.current.innerHTML = contentToSet;
            }
        }
        setCharCount(value?.length || 0);
    }, [value, activeTab]);

    // Manejar cambios en el editor simple
    const handleSimpleEditorChange = (e) => {
        const newContent = e.currentTarget.innerHTML;
        if (onChange) {
            onChange({
                target: {
                    value: newContent
                }
            });
        }
        setCharCount(newContent.length);
    };

    // Manejar cambios en el editor HTML
    const handleChange = (e) => {
        setCharCount(e.target.value.length);
        onChange?.(e);
    };

    // Manejar tecla Enter en modo HTML
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            
            const textarea = textAreaRef.current.resizableTextArea.textArea;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const textBefore = value.substring(0, start);
            const textAfter = value.substring(end);

            const lastLines = textBefore.split('\n');
            const lastLine = lastLines[lastLines.length - 1];
            const isInList = lastLine.includes('<li>');
            const isInParagraph = lastLine.includes('<p>');

            let insertedText;
            if (isInList) {
                insertedText = '\n<li></li>';
            } else {
                insertedText = isInParagraph ? '\n<p></p>' : '<p></p>';
            }

            const newText = textBefore + insertedText + textAfter;
            onChange?.({
                target: {
                    value: newText
                }
            });

            setTimeout(() => {
                textarea.focus();
                const newPos = start + insertedText.length - 4;
                textarea.setSelectionRange(newPos, newPos);
            }, 0);
        }
    };

    // Aplicar formato en el editor simple
    const applySimpleFormat = (button) => {
        document.execCommand('styleWithCSS', false, true);
        let command = '';
        
        switch (button.action) {
            case 'bold':
                command = 'bold';
                break;
            case 'italic':
                command = 'italic';
                break;
            case 'underline':
                command = 'underline';
                break;
            case 'alignLeft':
                command = 'justifyLeft';
                break;
            case 'alignCenter':
                command = 'justifyCenter';
                break;
            case 'alignRight':
                command = 'justifyRight';
                break;
            case 'orderedList':
                command = 'insertOrderedList';
                break;
            case 'unorderedList':
                command = 'insertUnorderedList';
                break;
            case 'link':
                const url = prompt('Ingrese la URL:', 'http://');
                if (url) {
                    command = 'createLink';
                    document.execCommand(command, false, url);
                }
                return;
        }

        if (command) {
            document.execCommand(command, false, null);
        }

        // Actualizar el valor después de aplicar el formato
        if (onChange) {
            onChange({
                target: {
                    value: simpleEditorRef.current.innerHTML
                }
            });
        }
    };

    // Manejar acciones de la barra de herramientas
    // Manejar cambio de tamaño de texto
    const handleFontSizeChange = (size, button) => {
        if (!size) return;
    
        if (activeTab === '0') {
            simpleEditorRef.current?.focus();
            const selection = window.getSelection();
            
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                
                // Función para verificar si un nodo es parte del editor
                const isWithinEditor = (node) => {
                    return simpleEditorRef.current.contains(node) || node === simpleEditorRef.current;
                };
    
                // Función para encontrar el span más cercano con font-size
                const findFontSizeSpan = (node) => {
                    if (!node || !isWithinEditor(node)) return null;
                    while (node && isWithinEditor(node)) {
                        if (node.nodeType === 1 && node.tagName === 'SPAN' && node.style.fontSize) {
                            return node;
                        }
                        node = node.parentElement;
                    }
                    return null;
                };
    
                // Obtener el nodo contenedor y verificar si ya tiene un span con font-size
                const container = range.commonAncestorContainer;
                const existingSpan = findFontSizeSpan(
                    container.nodeType === 3 ? container.parentNode : container
                );
    
                if (existingSpan) {
                    // Si ya existe un span con font-size, actualizar su tamaño
                    if (selection.isCollapsed) {
                        // Si no hay selección, solo actualizar el span
                        existingSpan.style.fontSize = size;
                    } else {
                        // Guardar la selección original
                        const originalRange = range.cloneRange();
                        
                        // Seleccionar todo el contenido del span existente
                        range.selectNode(existingSpan);
                        
                        // Si la selección original está dentro del span
                        if (range.isPointInRange(originalRange.startContainer, originalRange.startOffset) &&
                            range.isPointInRange(originalRange.endContainer, originalRange.endOffset)) {
                            // Cambiar el tamaño del span existente
                            existingSpan.style.fontSize = size;
                            
                            // Restaurar la selección original
                            selection.removeAllRanges();
                            selection.addRange(originalRange);
                        } else {
                            // Si la selección es diferente, crear un nuevo span
                            const newSpan = document.createElement('span');
                            newSpan.style.fontSize = size;
                            
                            try {
                                originalRange.surroundContents(newSpan);
                            } catch (e) {
                                const fragment = originalRange.extractContents();
                                newSpan.appendChild(fragment);
                                originalRange.insertNode(newSpan);
                            }
                            
                            // Restaurar selección después del nuevo span
                            selection.removeAllRanges();
                            selection.addRange(originalRange);
                        }
                    }
                } else {
                    // Si no existe un span, crear uno nuevo
                    const span = document.createElement('span');
                    span.style.fontSize = size;
                    
                    if (!selection.isCollapsed) {
                        // Si hay texto seleccionado
                        try {
                            range.surroundContents(span);
                        } catch (e) {
                            const fragment = range.extractContents();
                            span.appendChild(fragment);
                            range.insertNode(span);
                        }
                    } else {
                        // Si no hay selección, insertar nuevo texto
                        span.textContent = 'texto';
                        range.insertNode(span);
                        
                        // Mover el cursor después del span insertado
                        const newRange = document.createRange();
                        newRange.setStartAfter(span);
                        newRange.collapse(true);
                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    }
                }
    
                // Actualizar el valor del editor
                onChange?.({
                    target: {
                        value: simpleEditorRef.current.innerHTML
                    }
                });
            }
        } else if (activeTab === '1') {
            const textarea = textAreaRef.current.resizableTextArea.textArea;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = value.substring(start, end);
            
            const newText = `<span style="font-size: ${size};">${selectedText || 'texto'}</span>`;
            const textBefore = value.substring(0, start);
            const textAfter = value.substring(end);
            const finalText = textBefore + newText + textAfter;
    
            onChange?.({
                target: {
                    value: finalText
                }
            });
    
            setTimeout(() => {
                textarea.focus();
                const newPos = start + newText.length;
                textarea.setSelectionRange(newPos, newPos);
            }, 0);
        }
    
        // Resetear el selector de tamaño
        const select = document.querySelector('.font-size-select');
        if (select) {
            setTimeout(() => {
                select.value = '';
            }, 0);
        }
    };

    const handleToolbarAction = (button) => {
        if (activeTab === '0') {
            simpleEditorRef.current?.focus();
            applySimpleFormat(button);
        } else if (activeTab === '1') {
            const textarea = textAreaRef.current.resizableTextArea.textArea;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = value.substring(start, end);

            let newText = '';
            if (button.action.includes('List')) {
                const lines = selectedText.split('\n').filter(line => line.trim());
                const items = lines.map(line => `${button.itemStart}${line}${button.itemEnd}`).join('\n');
                newText = `${button.start}\n${items || `${button.itemStart}${button.itemEnd}`}\n${button.end}`;
            } else if (button.action === 'link') {
                const url = prompt('Ingrese la URL:', 'http://');
                if (url) {
                    button.start = `<a href="${url}">`;
                    newText = button.start + (selectedText || 'texto') + button.end;
                }
            } else {
                newText = button.start + (selectedText || '') + button.end;
            }

            if (newText) {
                const textBefore = value.substring(0, start);
                const textAfter = value.substring(end);
                const finalText = textBefore + newText + textAfter;

                onChange?.({
                    target: {
                        value: finalText
                    }
                });

                setTimeout(() => {
                    textarea.focus();
                    const newPos = start + newText.length;
                    textarea.setSelectionRange(newPos, newPos);
                }, 0);
            }
        }
    };

    // Renderizar vista previa
    const renderPreview = () => {
        const content = value || '';
        return (
            <div 
                className="editor-preview"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        );
    };

    return (
        <div className="editor-wrapper">
            <div className="editor-toolbar">
                {enabledButtons.map((group) => {
                    if (group === 'fontSize') {
                        return (
                            <Button.Group key={group} size="small">
                                <select
                                    className="font-size-select"
                                    onChange={(e) => handleFontSizeChange(e.target.value, TOOLBAR_BUTTONS[group][0])}
                                    style={{
                                        height: '32px',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '2px',
                                        padding: '4px 8px',
                                        marginRight: '8px',
                                        cursor: 'pointer',
                                        backgroundColor: '#fff',
                                        minWidth: '120px',
                                        fontSize: '14px'
                                    }}
                                >
                                    <option value="">Tamaño de texto</option>
                                    {TEXT_SIZES.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}px
                                        </option>
                                    ))}
                                </select>
                            </Button.Group>
                        );
                    }
                    
                    return TOOLBAR_BUTTONS[group] && (
                        <Button.Group key={group} size="small">
                            {TOOLBAR_BUTTONS[group].map((button) => (
                                <Button
                                    key={button.action}
                                    onClick={() => handleToolbarAction(button)}
                                    title={button.tooltip}
                                >
                                    <img 
                                        src={button.img} 
                                        alt={button.alt}
                                        style={{ 
                                            width: 20, 
                                            height: 20,
                                            verticalAlign: 'middle',
                                            padding: 3
                                        }} 
                                    />
                                </Button>
                            ))}
                        </Button.Group>
                    );
                })}
                {customButtons}
            </div>

            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tab="Editor Simple" key="0">
                    <div
                        ref={simpleEditorRef}
                        contentEditable
                        className="simple-editor"
                        onInput={handleSimpleEditorChange}
                        placeholder={placeholder}
                        style={{
                            minHeight: `${rows * 24}px`,
                            padding: '8px 12px',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            backgroundColor: '#fff',
                            overflowY: 'auto'
                        }}
                    />
                </TabPane>
                <TabPane tab="HTML" key="1">
                    <TextArea
                        ref={textAreaRef}
                        value={value}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        rows={rows}
                        placeholder={placeholder}
                        className="editor-textarea"
                    />
                </TabPane>
                <TabPane tab="Vista previa" key="2">
                    {renderPreview()}
                </TabPane>
            </Tabs>

            {(showCharCount || showHtmlHint) && (
                <div className="editor-statusbar">
                    {showCharCount && (
                        <span>{charCount} caracteres</span>
                    )}
                    {showHtmlHint && (
                        <span>HTML soportado</span>
                    )}
                </div>
            )}
        </div>
    );
};

export default EditorToolbar;