import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card, notification, Space } from 'antd';
import EditorToolbar from '../../components/utils/EditorToolbar/index';
import { getCookie } from "../../utils/cookies_functions";
import axios from 'axios';
import Nav from "../../components/NavSimple";
import ArrowLeftIcon from "../../img/icons/arrow_left.png";
import DoneIcon from "../../img/icons/done.png";
import CloseIcon from "../../img/icons/close.png";

const { TextArea } = Input;

const ForumPostCreate = () => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
            setSubmitting(true);
            const response = await axios.post(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts`,
                {
                    title: values.title,
                    description: values.description,
                    status: true
                },
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': getCookie('api_token'),
                        'Authorization': `Bearer ${getCookie('api_token')}`
                    }
                }
            );

            if (response.data.message === 'success') {
                notification.success({
                    message: 'Éxito',
                    description: 'El debate ha sido creado correctamente'
                });
                navigate(`/forum/post/${response.data.post.id}`);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.errors 
                ? Object.values(error.response.data.errors).join(', ')
                : 'No se pudo crear el debate';
                
            notification.error({
                message: 'Error',
                description: errorMessage
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <Nav />
            <div className="container py-4 mt-5">
                <Card 
                    title="Crear Debate"
                    className="shadow-sm"
                    extra={
                        <Button 
                            onClick={() => navigate('/forum')}
                            size="small" className='btn-secondary p-3'
                        >
                            <img src={ArrowLeftIcon} height={20} width={20} alt="Volver" />
                            Volver al Foro
                        </Button>
                    }
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Por favor ingresa un título' 
                                },
                                {
                                    max: 255,
                                    message: 'El título no puede exceder los 255 caracteres'
                                }
                            ]}
                        >
                            <Input 
                                placeholder="Escribe el título de tu debate"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Descripción"
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Ingresa la descripción' 
                                }
                            ]}
                        >
                            <EditorToolbar
                                placeholder="Describe detalladamente tu debate..."
                                rows={10}
                                enabledButtons={['basic', 'alignment', 'lists', 'insert']}
                                onChange={(e) => {
                                    form.setFieldsValue({
                                        description: e.target.value
                                    });
                                }}
                                value={form.getFieldValue('description') || ''}
                            />
                        </Form.Item>

                        <Form.Item className="mb-0">
                            <Space style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Button
                                    onClick={() => navigate('/forum')} 
                                    style={{fontSize: 13, textTransform: 'uppercase'}}
                                    size="large" 
                                    className='btn-secondary p-3'
                                >
                                    <img src={CloseIcon} height={20} width={20} alt="Cancelar" />
                                    Cancelar
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={submitting} 
                                    style={{fontSize: 13, textTransform: 'uppercase'}}
                                    size="large" 
                                    className='btn-primary p-3'
                                >
                                    <img src={DoneIcon} height={20} width={20} alt="Publicar" />
                                    Publicar
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>

                {/* Guía de ayuda */}
                <Card className="mt-4">
                    <h4 style={{fontSize: 16, fontWeight: '600', color: '#484848'}}>Consejos para un buen debate:</h4>
                    <ul className="mb-0" style={{fontSize: 12, marginTop: 15}}>
                        <li>Asegúrate de que el título sea claro y descriptivo.</li>
                        <li>Proporciona suficiente contexto en la descripción.</li>
                        <li>Sé específico en tu pregunta o tema de discusión.</li>
                        <li>Usa un lenguaje claro y respetuoso.</li>
                        <li>Revisa si existe un debate similar antes de crear uno nuevo.</li>
                    </ul>
                </Card>
            </div>
        </div>
    );
};

export default ForumPostCreate;