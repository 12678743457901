import React from "react";
import { Link } from "react-router-dom";
import LogoBusiness from "../img/business/business.svg"
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Loading from './includes/Loading';
import {getCookie, setCookie} from "../utils/cookies_functions";
import {getApiTokenFromUrl} from "../utils/app_functions";

export default class NavSimple extends React.Component{

    constructor(props) {
        super(props);
        this.initApiToken = this.initApiToken.bind(this);
    }

    componentDidMount(){
        this.initApiToken();
    }

    initApiToken(){
        const tokenFromUrl = getApiTokenFromUrl();
        if (tokenFromUrl) {
            console.log('tokenFromUrl', tokenFromUrl)
            setCookie('api_token', tokenFromUrl);
            
            const url = new URL(window.location.href);
            url.searchParams.delete('api_t');
            window.history.replaceState({}, '', url);
        }
    };

    render() {
        return (
            <div className="d-flex flex-column bg-white z-2 position-fixed top-0 w-100">
                <nav className="d-flex flex-row justify-content-between align-items-center bg-white z-2 w-100 shadow-down-medium">
                    <Link to={'/'} className="p-2 ml-2 d-flex flex-row align-items-center" title={"Página inicial del módulo de contabilidad"}>
                        <div>
                            <img id={'logo'} src={LogoBusiness} alt={process.env.REACT_APP_BUSINESS_NAME} width={35} height={35} className="mb-1" />
                            <Loading />
                        </div>
                        <div className="ml-3 mt-1">
                            <div className="color-secondary text-uppercase" style={{fontSize: 11}}>{process.env.REACT_APP_BUSINESS_NAME}</div>
                            <div className="color-secondary text-dark" style={{fontSize: 9}}>{process.env.REACT_APP_BUSINESS_COUNTRY}</div>
                        </div>
                    </Link>

                    <div className="p-2 d-flex flex-row align-items-center">
                        <Link to="/" className="mr-3" title={"Página principal"}>
                            <HomeIcon className="color-secondary" />
                        </Link>

                        <Link to="/menu" className="mr-2" title={"Menú de opciones con buscador de módulos y enlaces"}>
                            <MenuIcon className="color-secondary" />
                        </Link>
                    </div>
                </nav>
            </div>
        );
    }
}