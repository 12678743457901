const setCookie = (name,value,days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
const getCookie = (name) => {
    // Handle special characters in cookie names
    const cookieName = name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1');
    const matches = document.cookie.match(new RegExp(
        '(?:^|; )' + cookieName + '=([^;]*)'
    ));
    
    if (matches) {
        try {
            return decodeURIComponent(matches[1]);
        } catch (e) {
            // If decoding fails, return the raw value
            return matches[1];
        }
    }
    return null;
};
const removeCookie = (name) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * Debug function to list all cookies
 * @returns {Object} Object containing all cookies
 */
const getAllCookies = () => {
    return document.cookie
        .split(';')
        .reduce((cookies, cookie) => {
            const [name, value] = cookie.trim().split('=');
            try {
                cookies[name] = decodeURIComponent(value);
            } catch (e) {
                cookies[name] = value;
            }
            return cookies;
        }, {});
};

export {setCookie, getCookie, removeCookie, getAllCookies}