import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import axios from 'axios';
import { getCookie, setCookie } from "../utils/cookies_functions";

// Loading Component
const LoadingSpinner = () => (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
    }}>
        <Spin size="large" />
    </div>
);

export const WithAuth = (WrappedComponent) => {
    function AuthenticatedComponent(props) {
        const [isAuthenticated, setIsAuthenticated] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const location = useLocation();

        useEffect(() => {
            const checkAuth = async () => {
                try {
                    const api_token = getCookie('api_token');
                    
                    if (!api_token) {
                        throw new Error('No token found');
                    }

                    const response = await axios.post(
                        `${process.env.REACT_APP_REMOOX_API_MAIN}/security/user/auth/check`,
                        {},
                        {
                            headers: {
                                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                                'api_token': api_token,
                                'Authorization': `Bearer ${api_token}`
                            }
                        }
                    );
                    console.log('response.data2', response.data)
                    if (response.data.auth === true) {
                        setIsAuthenticated(true);
                        setCookie('usr_data', JSON.stringify(response.data.user), 0.1);
                    } else {
                        throw new Error('Authentication failed');
                    }
                } catch (error) {console.log('error',error)
                    setIsAuthenticated(false);
                    const currentPath = encodeURIComponent(location.pathname + location.search);
                    alert('Error al intentar conectar con REMOOX.');
                    throw new Error('El cliente no se pudo conectar con el servidor de REMOOX.');
                    
                    //window.location.href = `${process.env.REACT_APP_REMOOX_CORE_ROOT}/api/client/login?redirect=${currentPath}`;
                } finally {
                    setIsLoading(false);
                }
            };

            checkAuth();
        }, [location]);

        if (isLoading) {
            return <LoadingSpinner />;
        }

        if (!isAuthenticated) {
            return null;
        }

        return <WrappedComponent {...props} />;
    }

    AuthenticatedComponent.displayName = `WithAuth(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return AuthenticatedComponent;
};