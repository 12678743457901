import {useEffect, useState} from "react";
import {
    BrowserRouter, Routes, Route,
} from "react-router-dom";

// Google Analitycs 
// V3 Deprecated
//import ReactGA from 'react-ga';
//import ReactGA from "react-ga4";

import { WithAuth } from './middlewares/AuthRoutes'
import './App.css';
//import NotFound from './pages/NotFound'
import Home from './pages/Home'
import Menu from './pages/Menu'
import PropertyDetails from './pages/property/PropertyDetails'

// Forum Pages
import ForumList from './pages/Forum/ForumList';
import ForumPostCreate from './pages/Forum/ForumPostCreate';
import ForumPostEdit from './pages/Forum/ForumPostEdit';
//import EditDiscuss from './pages/Forum/EditDiscuss';

// Protected components
const ProtectedForumPostCreate = WithAuth(ForumPostCreate);
//const ProtectedForumPostEdit = WithAuth(ForumPostEdit);
//const ProtectedCreatePost = WithAuth(CreatePost);
//const ProtectedEditPost = WithAuth(EditPost);

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>} exact />
                <Route path="/menu" element={<Menu/>}/>
                <Route path="/property/details/:id" element={<PropertyDetails/>} />

                {/* Rutas del foro protegidas */}
                <Route path="/forum" element={<ForumList />} />
                
                <Route path="/forum/create" element={<ProtectedForumPostCreate />} />
                <Route path="/forum/post/:id" element={<ForumPostEdit />} />
                {/*<Route path="/forum/edit/:id" element={<ProtectedEditPost />} />*/}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
