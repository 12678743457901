import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Input, Button, Card, notification, Space } from 'antd';
import EditorToolbar from '../../components/utils/EditorToolbar/index';
import { getCookie } from "../../utils/cookies_functions";
import axios from 'axios';
import NavSimple from "../../components/NavSimple";
import ArrowLeftIcon from "../../img/icons/arrow_left.png";
import DoneIcon from "../../img/icons/done.png";
import CloseIcon from "../../img/icons/close.png";
import LoginIcon from '../../img/icons/login.svg'
import '../../css_pages/ForumPostEdit.css';
import ForumPostAnswer from './ForumPostAnswer';
import GoogleAdsenseBoxDisplay from '../../components/ads/GoogleAdsenseBoxDisplay';

const ForumPostEdit = () => {
    const [form] = Form.useForm();
    const [isAuthor, setIsAuthor] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    });
    const navigate = useNavigate();
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const location = useLocation();

    // Efecto para inicializar el formulario
    useEffect(() => {
        form.setFieldsValue(formData);
    }, []);

    // Efecto para manejar cambios en los datos
    useEffect(() => {
        if (post) {
            const newFormData = {
                title: post.title || '',
                description: post.description || ''
            };
            setFormData(newFormData);
            form.setFieldsValue(newFormData);
        }
    }, [post]);

    const fetchPost = async (post_id) => {
        try {
            const api_token = getCookie('api_token');

            const response = await axios.get(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/${post_id}`,
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Authorization': `Bearer ${api_token}`
                    }
                }
            );
            
            const postData = response.data.post;
            setPost(postData);
            
            setIsAuthor(Boolean(postData.is_author));

            const initialFormData = {
                title: postData.title || '',
                description: postData.description || ''
            };
            setFormData(initialFormData);
            form.setFieldsValue(initialFormData);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching post:', error);
            notification.error({
                message: 'Error',
                description: 'No se pudo cargar el debate'
            });
            navigate('/forum');
        }
    };

    useEffect(() => {
        const currentPostId = postId || location.pathname.split('/').pop();
        if (currentPostId) {
            console.log('currentPostId', currentPostId)
            fetchPost(currentPostId);
        }
    }, [postId, location.pathname]);

    const onFinish = async (values) => {
        if (!isAuthor) return;
        
        try {
            setSubmitting(true);
            const response = await axios.post(
                `${process.env.REACT_APP_REMOOX_API_MAIN}/v1/forum/posts/update`,
                {
                    id: post?.id,
                    title: values.title || '',
                    description: values.description || '',
                    status: true
                },
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': getCookie('api_token'),
                        'Authorization': `Bearer ${getCookie('api_token')}`
                    }
                }
            );
    
            if (response.data.message === 'success') {
                notification.success({
                    message: 'Éxito',
                    description: 'El debate ha sido actualizado correctamente'
                });
                navigate(`/forum/post/${post?.id}`);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.errors 
                ? Object.values(error.response.data.errors).join(', ')
                : 'No se pudo actualizar el debate';
                        
            notification.error({
                message: 'Error',
                description: errorMessage
            });
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div>
                <NavSimple />
                <div className="container py-4 mt-5">
                    <Card loading={true} />
                </div>
            </div>
        );
    }
    
    // Vista de solo lectura para no autores
    if (!isAuthor && post) {
        return (
            <div>
                <NavSimple />
                <div className="container py-4 mt-5">
                    <Card 
                        title={post.title || ''}
                        style={{border: '1px solid rgb(167 167 167)', background: '#ffffff'}}
                        extra={
                            <Button 
                                onClick={() => navigate('/forum')}
                                size="small" title="Volver al Foro"
                                className="btn-secondary p-3"
                            >
                                <img src={ArrowLeftIcon} height={20} width={20} alt="Volver" />
                                
                            </Button>
                        }
                    >
                        <div className="post-content">
                            <div className="post-meta mb-2 d-flex flex-row align-items-center" style={{fontSize: 12, color: '#424242'}}>
                                <p className=''><strong>Autor:</strong> {post.user.firstname + ' ' + post.user.lastname || 'Anónimo'}</p>
                                <p className='ml-5'><strong>Estado:</strong> {post.status ? 'Público' : 'Privado'}</p>
                            </div>
                            <div 
                                className="post-description"
                                dangerouslySetInnerHTML={{ __html: post.description || '' }} 
                            />
                        </div>

                        

                        {
                            !getCookie('api_token') &&
                            <a href={`${process.env.REACT_APP_REMOOX_CORE_ROOT}/api/client/login?route_after=${process.env.REACT_APP_URL}/forum/post/${post.id}&type=forum`}
                                className="card w-100-mb p-2 m-1 mt-5 d-flex flex-column align-items-center justify-content-center"
                                style={{minWidth: '12%'}}>
                                <div className="d-flex flex-row align-items-center">
                                    <div className="icon-circle-menu-link">
                                        <div className="icon-circle-menu" style={{width: '30px', height: '30px'}}>
                                            <img src={LoginIcon} height={20} width={20} alt="|"/>
                                        </div>
                                    </div>
                                    <div className="name_tool ml-3" style={{fontSize: 16, fontWeight: '400'}}>Conecta y Participa</div>
                                </div>
                            </a>
                        }

                        <ForumPostAnswer 
                            postId={post.id}
                            isAuthenticated={Boolean(getCookie('api_token'))}
                        />

                        <GoogleAdsenseBoxDisplay />
                    </Card>
                </div>
            </div>
        );
    }

    // Vista de edición para autores
    return (
        <div>
            <NavSimple />
            <div className="container py-4 mt-5">
                <Card 
                    title="Editar Debate"
                    className="shadow-sm"
                    extra={
                        <Button 
                            onClick={() => navigate(`/forum`)}
                            size="small" 
                            className="btn-secondary p-3"
                        >
                            <img src={ArrowLeftIcon} height={20} width={20} alt="Volver" />
                            Volver al Foro
                        </Button>
                    }
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={formData}
                        preserve={false}
                    >
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[
                                { required: true, message: 'Por favor ingresa un título' },
                                { max: 255, message: 'El título no puede exceder los 255 caracteres' }
                            ]}
                        >
                            <Input 
                                placeholder="Escribe el título de tu debate"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Descripción"
                            rules={[{ required: true, message: 'Por favor ingresa una descripción' }]}
                        >
                            <EditorToolbar
                                placeholder="Describe detalladamente tu debate..."
                                rows={10}
                                enabledButtons={['basic', 'alignment', 'lists', 'insert']}
                                onChange={(e) => {
                                    form.setFieldsValue({
                                        description: e.target.value || ''
                                    });
                                }}
                                defaultValue={formData.description}
                            />
                        </Form.Item>

                        <Form.Item className="mb-0">
                            <Space style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Button
                                    onClick={() => navigate(`/forum`)} 
                                    style={{fontSize: 13, textTransform: 'uppercase'}}
                                    size="large" 
                                    className="btn-secondary p-3"
                                >
                                    <img src={CloseIcon} height={20} width={20} alt="Cerrar" />
                                    Cerrar
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={submitting}
                                    style={{fontSize: 13, textTransform: 'uppercase'}}
                                    size="large" 
                                    className="btn-primary p-3"
                                >
                                    <img src={DoneIcon} height={20} width={20} alt="Guardar" />
                                    Guardar Cambios
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                    <div className="mt-4">
                        <ForumPostAnswer 
                            postId={post.id}
                            isAuthenticated={Boolean(getCookie('api_token'))}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ForumPostEdit;